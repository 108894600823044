var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-row', {
    staticClass: "justify-content-center"
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "10",
      "lg": "9"
    }
  }, [_c('b-card', [_c('b-form-group', {
    staticClass: "mb-2",
    attrs: {
      "label": "Nama Instansi"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Ex: PT. NAMA PERUSAHAAN"
    },
    model: {
      value: _vm.setting.instansi,
      callback: function callback($$v) {
        _vm.$set(_vm.setting, "instansi", $$v);
      },
      expression: "setting.instansi"
    }
  })], 1), _vm.setting.path ? _c('b-form-group', {
    staticClass: "mb-2",
    attrs: {
      "label": "Logo"
    }
  }, [_c('div', [_c('img', {
    staticClass: "d-block mb-1",
    staticStyle: {
      "width": "70px"
    },
    attrs: {
      "src": _vm.setting.path,
      "alt": ""
    }
  }), _c('input', {
    attrs: {
      "type": "file"
    },
    on: {
      "change": _vm.onSelectedLogo
    }
  })])]) : _vm._e(), _c('b-form-group', {
    staticClass: "mb-2",
    attrs: {
      "label": "Jenis Usaha"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Ex: Pertambangan"
    },
    model: {
      value: _vm.setting.jenis_usaha,
      callback: function callback($$v) {
        _vm.$set(_vm.setting, "jenis_usaha", $$v);
      },
      expression: "setting.jenis_usaha"
    }
  })], 1), _c('b-form-group', {
    staticClass: "mb-2",
    attrs: {
      "label": "Website"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Ex: www.perusahaanmu.co.id"
    },
    model: {
      value: _vm.setting.website,
      callback: function callback($$v) {
        _vm.$set(_vm.setting, "website", $$v);
      },
      expression: "setting.website"
    }
  })], 1), _c('b-form-group', {
    staticClass: "mb-2",
    attrs: {
      "label": "Alamat"
    }
  }, [_c('quill-editor', {
    attrs: {
      "options": _vm.editorOptions
    },
    model: {
      value: _vm.setting.alamat,
      callback: function callback($$v) {
        _vm.$set(_vm.setting, "alamat", $$v);
      },
      expression: "setting.alamat"
    }
  })], 1), _c('b-button', {
    staticClass: "text-right",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v(" Simpan ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }